import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart';
import { ChartDatum, ChartLabels } from '../common/HorizontalStackedBarChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError';
import { tooltipPercentFormatter } from '../common/tooltipUtilities';

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [state: string]: { [date: string]: number};
}

class StateLoanCountPercentTrendChart extends React.PureComponent<Props, State> {
  cache = { states: [] as string[], filteredData: {} as RawData };

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'Loan Count %',
        yAxis: 'Valuation Date'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/stratifications/state_loan_count_percent`, { valuation_id: this.props.valuation.id, items: 6 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToChartData(this.filterData(response.data)),
        dataKeys: this.states(this.filterData(response.data)),
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let statePercentagesByDate: { [date: string]: { [state: string]: number } } = {};

    for (const state of this.states(rawData)) {
      const percentagesByDate = rawData[state];
      for (const [unformattedDateString, percentage] of Object.entries(percentagesByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (statePercentagesByDate.hasOwnProperty(dateString)) || (statePercentagesByDate[dateString] = {});
        statePercentagesByDate[dateString][state] = 100.0 * percentage;
      }
    }

    let chartData: ChartDatum[] = [];
    for (const [date, percentagesByState] of Object.entries(statePercentagesByDate)) {
      const entry = { name: date, ...percentagesByState };
      chartData.push(entry);
    }

    return chartData;
  }

  filterData(rawData: RawData) {
    if (Object.keys(this.cache.filteredData).length > 0) return this.cache.filteredData;

    delete rawData['Current'];
    this.cache.filteredData = rawData;
    return rawData;
  }

  formatDate(dateString: string): string {
    return formatShort(stringToDate(dateString));
  }

  states(rawData: RawData): string[] {
    if (this.cache.states.length > 0) return this.cache.states;
    const unsortedStates = Object.keys(rawData);

    const sampleState = unsortedStates[0];

    const sortedDates = Object.keys(rawData[sampleState]).sort((a, b) => a > b ? 1 : -1)
    const mostRecentDate = sortedDates[sortedDates.length - 1];

    const sortedStates = unsortedStates.sort((a, b) => {
      if (a.toUpperCase() == 'OTHERS') return 1;
      if (b.toUpperCase() == 'OTHERS') return -1;
      return rawData[a][mostRecentDate] > rawData[b][mostRecentDate] ? -1 : 1
    })
    this.cache.states = sortedStates;
    return sortedStates;
  }

  yTickFormatter = (tick: any) => {
    return `${tick}`;
  }

  xTickFormatter = (tick: any) => {
    return `${tick}%`
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <HorizontalStackedBarChart
    xTickFormatter={this.xTickFormatter}
    yTickFormatter={this.yTickFormatter}
    tooltipFormatter={tooltipPercentFormatter}
    data={this.state.data}
    barDataKeys={this.state.dataKeys}
    labels={this.state.labels}
    barCategoryGap={2}
    containerHeight={280} />
  }
}

export default StateLoanCountPercentTrendChart;
